<template lang="">
  <div>
    <v-card :elevation="0" class="v-sheet theme--light br-0">
      <div class="row mt-0 mb-0">
        <div
          class="col-12 col-md-12 m-0 p-0"
          style="margin-top: -5px !important;"
        >
          <DivisorColor :ptl="`3px`" :ptr="`3px`" :pbl="`3px`" :pbr="`3px`" />
        </div>
      </div>

      <v-card-title>
        <v-row class="col-md-12">
          <v-col cols="12" sm="12" md="8">
            Solicitud de usuarios proyecto
          </v-col>
          <v-col cols="12" sm="12" md="4"> </v-col>
        </v-row>
        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="validFormSolicitud">
          <v-row>
            <v-col cols="12" md="6" sm="6" xs="12" class="pt-1 pb-0">
              <v-text-field
                autocomplete="off"
                class=""
                dense
                filled
                v-model="fechaActual"
                label="Fecha de registro de la solicitud"
                :disabled="true"
                maxlength="20"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
              <v-select
                v-model="tipoProyectoSeleccionado"
                :items="tiposProyecto"
                class="required"
                dense
                filled
                :loading="false"
                label="Tipo de Proyecto"
                :item-text="item => `${item.tipo}`"
                item-value="id"
                :rules="[selectRequired('tipo de proyecto')]"
                :disabled="confirmado"
                return-object
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="3" sm="6" xs="12" class="pt-0 pb-0">
              <v-text-field
                autocomplete="off"
                class="required"
                dense
                filled
                v-model="nombresSolicitante"
                label="Nombres del solicitante (jefe inmediato)"
                :rules="[
                  required('nombres del solicitante (jefe inmediato)'),
                  /*minLength('nombres del solicitante', 5),*/
                  maxLength('nombres del solicitante (jefe inmediato)', 50)
                ]"
                maxlength="50"
                :disabled="confirmado"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3" sm="6" xs="12" class="pt-0 pb-0">
              <v-text-field
                autocomplete="off"
                class="required"
                dense
                filled
                v-model="apellidosSolicitante"
                label="Apellidos de solicitante (jefe inmediato)"
                :rules="[
                  required('apellidos de solicitante (jefe inmediato)'),
                  /*minLength('apellidos de solicitante', 5),*/
                  maxLength('apellidos de solicitante (jefe inmediato)', 50)
                ]"
                maxlength="50"
                :disabled="confirmado"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3" sm="6" xs="12" class="pt-0 pb-0">
              <v-text-field
                autocomplete="off"
                class="required"
                dense
                filled
                v-model="datosSolicitud.correoSolicitante"
                label="Correo electrónico del solicitante"
                maxlength="150"
                :rules="[required('Correo del solicitante'), validEmail('')]"
                :disabled="confirmado"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3" sm="6" xs="12" class="pt-0 pb-0">
              <v-text-field
                autocomplete="off"
                class="required"
                dense
                filled
                v-model="datosSolicitud.cargoSolicitante"
                label="Cargo del solicitante (Puesto nominal)"
                maxlength="100"
                :rules="[
                  required('cargo del solicitante (Puesto nominal)'),
                  minLength('cargo del solicitante (Puesto nominal)', 2),
                  maxLength('cargo del solicitante (Puesto nominal)', 100)
                ]"
                :disabled="confirmado"
              ></v-text-field>
            </v-col>
          </v-row>

          <!--                     <v-row>
                        <v-col cols="12" md="6" sm="12" xs="12" class="pt-0 pb-0">
                            <v-text-field
                                autocomplete="off"
                                class="required"
                                dense
                                filled
                                v-model="datosSolicitud.nombreAprobador"
                                label="Nombre completo de quien aprueba la solicitud"
                                :rules="[
                                    required('nombre del aprobador'),
                                    minLength('nombre del aprobador', 5),
                                    maxLength('nombre del aprobador', 100)
                                ]"
                                maxlength="100"
                                :disabled="confirmado"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                            <v-text-field
                                autocomplete="off"
                                class="required"
                                dense
                                filled
                                v-model="datosSolicitud.cargoAprobador"
                                label="Cargo"
                                maxlength="100"
                                 :rules="[
                                    required('cargo'),
                                    minLength('cargo', 4),
                                    maxLength('cargo', 100)
                                ]"
                                :disabled="confirmado"
                            ></v-text-field>
                        </v-col>
                    </v-row> -->

          <v-row>
            <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
              <v-autocomplete
                v-model="entidadSeleccionada"
                :items="instituciones"
                class="required"
                dense
                filled
                :loading="ddInstitucionesLoading"
                label="Institución"
                :item-text="
                  item =>
                    item.sigla
                      ? `${item.nombreInstitucion} (${item.sigla})`
                      : item.nombreInstitucion
                "
                item-value="id"
                :rules="[selectRequired('institución')]"
                :disabled="confirmado"
                @change="
                  obtenerProyectosSicooperaEntidadUnidadEjecutora(
                    entidadSeleccionada.id,
                    0
                  );
                  obtenerUnidadesEjecutorasPorEntidad(entidadSeleccionada.id);
                "
                return-object
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
              <v-autocomplete
                v-model="unidadEjecutoraSeleccionada"
                :items="unidadesEjecutoras"
                :clearable="true"
                class=""
                dense
                filled
                :loading="unidadesEjecutorasLoading"
                label="Dependencia"
                item-text="unidadEjecutora"
                item-value="id"
                :disabled="confirmado"
                @change="
                  obtenerProyectosSicooperaEntidadUnidadEjecutora(
                    entidadSeleccionada.id,
                    unidadEjecutoraSeleccionada.id
                  )
                "
                return-object
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
              <v-text-field
                autocomplete="off"
                class="required"
                dense
                filled
                v-model="datosSolicitud.nombreMaximaAutoridad"
                label="Nombre de la máxima autoridad que aprueba la solicitud"
                maxlength="100"
                :rules="[
                  required(
                    'Nombre de la máxima autoridad que aprueba la solicitud'
                  ),
                  minLength(
                    'Nombre de la máxima autoridad que aprueba la solicitud',
                    4
                  ),
                  maxLength(
                    'Nombre de la máxima autoridad que aprueba la solicitud',
                    100
                  )
                ]"
                hint="* La solicitud debe ser aprobada por: Ministro / Viceministro o
                Secretario / Subsecretario"
                :disabled="confirmado"
              ></v-text-field>
              <p style="color: #fd397a">
                * La solicitud debe ser aprobada por: Ministro / Viceministro o
                Secretario / Subsecretario
              </p>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-checkbox
                v-model="solicitanteUsuario"
                :disabled="
                  nombresSolicitante.length <= 0 ||
                    apellidosSolicitante.length <= 0 ||
                    datosSolicitud.correoSolicitante.length <= 0 ||
                    !entidadSeleccionada ||
                    entidadSeleccionada.id == 0 ||
                    entidadSeleccionada.id == null ||
                    !tipoProyectoSeleccionado ||
                    tipoProyectoSeleccionado.id == null ||
                    tipoProyectoSeleccionado.id == 0
                "
                @change="agregarUsuarioSolicitante"
                :label="
                  `En esta solicitud de usuario, ¿también se incluirá una para el solicitante (jefe inmediato)?`
                "
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>

        <v-row class="mt-6">
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-3">
            <v-subheader class="text-h6 black--text">
              Usuarios
            </v-subheader>
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="12" sm="12" class="pt-0 pb-0">
            <v-spacer></v-spacer>

            <!--:disabled="!validDocForm" type="submit"-->
            <v-btn
              color="light-blue-502"
              class="white--text mb-2 float-left"
              type="submit"
              :elevation="0"
              :disabled="
                !validFormSolicitud ||
                  !tipoProyectoSeleccionado ||
                  tipoProyectoSeleccionado.id == null ||
                  tipoProyectoSeleccionado.id == 0
              "
              :loading="false"
              @click="mostrarModalUsuario()"
            >
              <v-icon>mdi-plus</v-icon>Agregar
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="mt-6">
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-3">
            <v-subheader class="text-h6 black--text">
              Usuarios agregados ({{ usuariosSolicitud.length }})
            </v-subheader>
          </v-col>
        </v-row>

        <v-row class="mt-6">
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-3">
            <v-data-table
              class="elevation-1"
              :headers="headersUsuarios"
              :items="usuariosSolicitud"
              :loading="tableRolesLoading"
              hide-default-footer
              no-data-text="No hay usuarios agregados para la solicitud"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                'items-per-page-text': 'Registros por página',
                pageText: '{0}-{1} de {2}'
              }"
            >
              <template v-slot:[`item.nombres`]="{ item }">
                {{ item.nombres }} {{ item.apellidos }}
              </template>

              <template v-slot:[`item.correo`]="{ item }">
                {{ item.correo }}
              </template>

              <!-- <template v-slot:[`item.entidad`]="{ item }">
                                    {{item.entidad}}, {{item.unidadEjecutora}}
                                </template> -->

              <template v-slot:[`item.proyecto`]="{ item }">
                <v-row class="p-5">
                  <v-col
                    cols="12"
                    md="12"
                    class="pt-0 pb-0"
                    v-for="proyecto in item.proyectos"
                    :key="proyecto.id"
                  >
                    {{ proyecto.nombreProyecto }}
                  </v-col>
                </v-row>
              </template>

              <template v-slot:[`item.area`]="{ item }">
                {{ item.area }}, {{ item.cargo }}
              </template>

              <template v-slot:[`item.accion`]="{ item }">
                <v-btn
                  class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="eliminarUsuario(item.correo)"
                >
                  <v-icon left>mdi-delete-empty</v-icon>Eliminar
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-row class="mt-8">
          <v-col md="12" sm="12" class="pt-0 pb-0 text-center">
            <v-spacer></v-spacer>

            <!--:disabled="!validDocForm" type="submit"-->
            <v-btn
              color="light-blue-502"
              class="white--text mb-2"
              type="submit"
              :elevation="0"
              :loading="false"
              x-large
              :disabled="
                !validFormSolicitud ||
                  usuariosSolicitud.length <= 0 ||
                  nombresSolicitante.length <= 0 ||
                  apellidosSolicitante.length <= 0 ||
                  datosSolicitud.correoSolicitante.length <= 0
              "
              @click="detalleSolicitud()"
            >
              <v-icon>mdi-check-bold</v-icon>
              {{ btnConfirmacionText }}
            </v-btn>
          </v-col>
        </v-row>

        <v-row v-if="mostrarMensajeDescarga">
          <v-col cols="12" md="12">
            <v-alert outlined type="success" text>
              Se ha registrado correctamente su solicitud con código
              {{ codigoSolicitud }}, se descargará un documento el cual debe ser
              firmado por el solicitante y la máxima autoridad de la
              institución, posteriormente enviarlo como un documento adjunto al
              correo sicooperagt@segeplan.gob.gt. Si no se descarga
              automáticamente la solicitud, por favor, haga click en el
              siguiente enlace.
              <a
                :href="directDownloadLink"
                v-text="'Descargar archivo'"
                @click.prevent="descargarArchivo(directDownloadLink)"
              />
            </v-alert>
          </v-col>
        </v-row>

        <v-row v-if="mostrarMensajeNoGenerado">
          <v-col cols="12" md="12">
            <v-alert outlined type="success" text>
              Se ha registrado correctamente, sin embargo, no hemos podido
              generar el archivo correspondiente. Por favor, diríjase a la zona
              de solicitud y genere nuevamente con el código proporcionado
              {{ codigoSolicitud }}.
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="dialogUsuario"
      max-width="1000px"
      persistent
      transition="scroll-y-transition"
    >
      <v-card>
        <v-card-title>
          Agregar usuario a la lista
          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="
              dialogUsuario = false;
              resetFormUsuario();
            "
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="p-10">
          <v-form
            ref="form"
            v-on:submit.prevent="agregarUsuario"
            v-model="validFormUsuario"
          >
            <v-row
              v-if="
                tipoProyectoSeleccionado && tipoProyectoSeleccionado.id === 1
              "
            >
              <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                <v-autocomplete
                  v-model="proyectosSeleccionados"
                  :items="proyectos"
                  class="required"
                  :multiple="true"
                  chips
                  dense
                  filled
                  :loading="proyectosLoading"
                  label="Proyecto"
                  :item-text="item => item.codigo + ` | ` + item.nombreProyecto"
                  item-value="id"
                  :rules="[
                    tipoProyectoSeleccionado.id
                      ? selectRequired('proyecto')
                      : true,
                  ]"
                  :disabled="confirmado"
                  return-object
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="toggle">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            proyectosSeleccionados.length > 0
                              ? 'indigo darken-4'
                              : ''
                          "
                        >
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Seleccionar todos
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                <v-text-field
                  autocomplete="off"
                  class="required"
                  dense
                  filled
                  v-model="datosUsuario.nombres"
                  label="Nombres del usuario"
                  :rules="[
                    required('nombre'),
                    /*minLength('nombre', 1),*/
                    maxLength('nombre', 100)
                  ]"
                  maxlength="100"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                <v-text-field
                  autocomplete="off"
                  class="required"
                  dense
                  filled
                  v-model="datosUsuario.apellidos"
                  label="Apellidos del usuario"
                  :rules="[
                    required('apellidos'),
                    /*minLength('apellidos', 1),*/
                    maxLength('apellidos', 100)
                  ]"
                  maxlength="100"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="8" sm="8" xs="12" class="pt-0 pb-0">
                <v-text-field
                  autocomplete="off"
                  class="required"
                  dense
                  filled
                  v-model="datosUsuario.correo"
                  label="Correo electrónico del usuario"
                  maxlength="150"
                  :rules="[required('Correo del usuario'), validEmail('')]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4" sm="4" xs="12" class="pt-0 pb-0">
                <v-text-field
                  autocomplete="off"
                  class="required"
                  dense
                  filled
                  v-model="datosUsuario.telefonos"
                  label="Número de teléfono"
                  :rules="[
                    required('teléfono'),
                    minLength('teléfono', 8),
                    maxLength('teléfono', 25)
                  ]"
                  maxlength="15"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                <v-text-field
                  autocomplete="off"
                  class="required"
                  dense
                  filled
                  v-model="datosUsuario.area"
                  label="Área o dirección a la que pertenece"
                  :rules="[
                    required('área o dirección a la que pertenece'),
                    /*minLength('área', 1),*/
                    maxLength('área o dirección a la que pertenece', 150)
                  ]"
                  maxlength="150"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                <v-text-field
                  autocomplete="off"
                  class="required"
                  dense
                  filled
                  v-model="datosUsuario.cargo"
                  label="Cargo (Puesto nominal)"
                  :rules="[
                    required('cargo (Puesto nominal)'),
                    /*minLength('cargo', 5),*/
                    maxLength('cargo (Puesto nominal)', 150)
                  ]"
                  maxlength="150"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                <v-textarea
                  autocomplete="off"
                  class="required"
                  dense
                  filled
                  rows="3"
                  v-model="datosUsuario.observaciones"
                  label="Observaciones (describa el renglón presupuestario del usuario)"
                  maxlength="2000"
                  :rules="[
                    minLength('observaciones', 3),
                    maxLength('observaciones', 2000)
                  ]"
                  hint="Describa el renglón presupuestario del usuario"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="12" sm="12" class="pt-0 pb-0">
                <v-spacer></v-spacer>

                <!--:disabled="!validDocForm" type="submit"-->
                <v-btn
                  color="light-blue-502"
                  class="white--text mb-2 float-right"
                  type="submit"
                  :elevation="0"
                  :disabled="!validFormUsuario"
                  :loading="false"
                >
                  Agregar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogResumen"
      max-width="1000px"
      persistent
      transition="scroll-y-transition"
    >
      <v-card>
        <v-card-title>
          Detalle de la solicitud a procesar
          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="dialogResumen = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="p-10 pt-1">
          <v-row class="mt-1">
            <v-col cols="12" md="12" sm="12" xs="12" class="pb-0">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h6"
                    >Fecha de la solicitud</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-subtitle-1">{{
                    fechaActual
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>

            <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h6"
                    >Nombre del solicitante</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-subtitle-1"
                    >{{ nombresSolicitante }}
                    {{ apellidosSolicitante }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-col>

            <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h6"
                    >Correo del solicitante</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-subtitle-1">{{
                    datosSolicitud.correoSolicitante
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>

          <v-row class="mt-8">
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-subheader class="text-h6 black--text">
                Usuarios a solicitar
              </v-subheader>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12" sm="12" class="pt-2 pb-3">
              <v-data-table
                class="elevation-1"
                :headers="headersUsuariosResumen"
                :items="usuariosSolicitud"
                :loading="tableRolesLoading"
                hide-default-footer
                no-data-text="No hay usuarios agregados para la solicitud"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-page-first',
                  lastIcon: 'mdi-page-last',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  'items-per-page-text': 'Registros por página',
                  pageText: '{0}-{1} de {2}'
                }"
              >
                <template v-slot:[`item.nombres`]="{ item }">
                  {{ item.nombres + ` ` + item.apellidos }}
                </template>

                <template v-slot:[`item.correo`]="{ item }">
                  {{ item.correo }}
                </template>

                <!-- <template v-slot:[`item.entidad`]="{ item }">
                                        {{item.entidad}}, {{item.unidadEjecutora}}
                                    </template> -->

                <template v-slot:[`item.proyecto`]="{ item }">
                  <v-row class="p-5">
                    <v-col
                      cols="12"
                      md="12"
                      class="pt-0 pb-0"
                      v-for="proyecto in item.proyectos"
                      :key="proyecto.id"
                    >
                      {{ proyecto.nombreProyecto }}
                    </v-col>
                  </v-row>
                </template>

                <template v-slot:[`item.area`]="{ item }">
                  {{ item.area }}, {{ item.cargo }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h6"
                    >Nombre de la máxima autoridad que aprueba la
                    solicitud</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-subtitle-1">{{
                    datosSolicitud.nombreMaximaAutoridad
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>

          <v-row class="mt-4">
            <v-col md="12" sm="12" class="pt-0 pb-0">
              <v-spacer></v-spacer>

              <!--:disabled="!validDocForm" type="submit"-->
              <v-btn
                color="light-blue-502"
                class="white--text mb-2 float-right"
                type="submit"
                :elevation="0"
                :loading="false"
                x-large
                :disabled="
                  !validFormSolicitud ||
                    usuariosSolicitud.length <= 0 ||
                    nombresSolicitante.length <= 0 ||
                    apellidosSolicitante.length <= 0 ||
                    datosSolicitud.correoSolicitante.length <= 0
                "
                @click="registrarSolicitud()"
              >
                <v-icon>mdi-check-bold</v-icon>
                Confirmar
              </v-btn>

              <v-btn
                color="grey lighten-5"
                elevation="0"
                class="mb-2 float-right grey lighten-5 mr-1"
                :loading="false"
                x-large
                :disabled="false"
                @click="dialogResumen = false"
              >
                <v-icon>mdi-close</v-icon>
                Cancelar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
      transition="scroll-y-transition"
    ></DialogLoader>
    <!---->
  </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import DialogLoader from "@/view/content/DialogLoader";
import DivisorColor from "@/view/content/biblioteca-componentes/DivisorColor.vue";

import validations from "@/core/untils/validations.js";
import DownloadFile from "@/core/untils/downloadfile.js";

import { OBTENER_UNIDADES_EJECUTORAS_ENTIDAD } from "@/core/services/store/unidadesejecutoras/unidadejecutora.module";
import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
import { OBTENER_PROYECTOS_SICOOPERA_INSTITUCION_ENTIDAD } from "@/core/services/store/proyectoscns/proyectocns.module";

import { REGISTRAR_SOLICITUD_USUARIOS } from "@/core/services/store/solicitudesusuarios/solicitudusuario.module";

export default {
  name: "SolicitudUsuarios",
  components: {
    SnackAlert,
    DialogLoader,
    DivisorColor,
  },
  data() {
    return {
      mostrarMensajeDescarga: false,
      codigoSolicitud: "",
      validFormSolicitud: false,
      validFormUsuario: false,
      dialogUsuario: false,
      dialogResumen: false,
      nombresSolicitante: "",
      apellidosSolicitante: "",
      tiposProyecto: [
        { id: 1, tipo: "Cooperación Norte Sur", codigo: "CNS" },
        {
          id: 2,
          tipo: "Cooperación Sur Sur, Negociaciones Directas",
          codigo: "CSSD"
        },
        {
          id: 3,
          tipo: "Cooperación Sur Sur, Negociaciones Comixtas",
          codigo: "CSSC"
        },
        { id: 4, tipo: "Alianzas", codigo: "ALI" }
      ],
      tipoProyectoSeleccionado: {},
      dialogLoaderVisible: false,
      dialogLoaderText: "",
      btnConfirmacionText: "Realizar solicitud",
      instituciones: [],
      unidadesEjecutorasLoading: false,
      unidadesEjecutoras: [],
      ddInstitucionesLoading: false,
      entidadId: "",
      unidadEjecutoraId: 0,
      proyectos: [],
      proyectosLoading: false,
      datosSolicitud: {
        entidadId: 0,
        unidadEjecutoraId: 0,
        correoSolicitante: "",
        nombreSolicitante: "",
        cargoSolicitante: "",
        nombreAprobador: "",
        cargoAprobador: "",
        nombreMaximaAutoridad: ""
      },
      entidadSeleccionada: null,
      unidadEjecutoraSeleccionada: null,
      proyectoSeleccionado: null,
      proyectosSeleccionados: [],
      datosUsuario: {
        nombres: "",
        apellidos: "",
        correo: "",
        telefono: "",
        area: "",
        cargo: "",
        proyectoId: 0,
        observaciones: ""
      },
      datosProceso: {},
      usuariosSolicitud: [],
      confirmado: false,
      mostrarMensajeNoGenerado: false,
      directDownloadLink: "",
      ...validations,
      solicitanteUsuario: false
    };
  },

  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllRoles) {
          this.proyectosSeleccionados = [];
        } else {
          this.proyectosSeleccionados = this.proyectos.slice();
        }
      });
    },

    //Obtener entidades o instituciones
    async obtenerEntidades() {
      this.instituciones = [];
      this.ddInstitucionesLoading = true;
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: `Entidades/all/1` })
        .then(res => {
          if (res.status === 200) {
            this.instituciones = res.data;
          }
          this.ddInstitucionesLoading = false;
        })
        .catch(() => {
          this.instituciones = [];
          this.ddInstitucionesLoading = false;
        });
    },

    //Obtener las unidades ejecutoras
    async obtenerUnidadesEjecutorasPorEntidad(entidadId) {
      this.unidadesEjecutorasLoading = true;
      this.unidadesEjecutoras = [];

      await this.$store
        .dispatch(OBTENER_UNIDADES_EJECUTORAS_ENTIDAD, entidadId)
        .then(res => {
          if (res.status === 200) {
            this.unidadesEjecutoras = this.$store.state.unidadejecutora.unidadesEjecutoras;
          }

          this.unidadesEjecutorasLoading = false;
        })
        .catch(() => {
          this.unidadesEjecutorasLoading = false;
          this.unidadesEjecutoras = [];
        });
    },

    //Obtener las unidades ejecutoras
    async obtenerProyectosSicooperaEntidadUnidadEjecutora(
      entidadId,
      unidadEjecutoraId
    ) {
      this.proyectosLoading = true;
      this.proyectos = [];

      await this.$store
        .dispatch(OBTENER_PROYECTOS_SICOOPERA_INSTITUCION_ENTIDAD, {
          entidadId: entidadId,
          unidadEjecutoraId: unidadEjecutoraId,
          estadoId: 0
        })
        .then(res => {
          if (res.status === 200) {
            this.proyectos = res.data;
          }

          this.proyectosLoading = false;
        })
        .catch(() => {
          this.proyectosLoading = false;
          this.proyectos = [];
        });
    },

    mostrarModalUsuario() {
      this.resetFormUsuario();
      this.dialogUsuario = true;
    },

    //Mostrar el modal con los datos del usuario solicitante
    agregarUsuarioSolicitante() {
      if (this.solicitanteUsuario) {
        this.resetFormUsuario();
        this.proyectosSeleccionados = [];
        this.datosUsuario.nombres = this.nombresSolicitante;
        this.datosUsuario.apellidos = this.apellidosSolicitante;
        this.datosUsuario.correo = this.datosSolicitud.correoSolicitante;
        this.datosUsuario.telefonos = "";
        this.datosUsuario.area = "";
        this.datosUsuario.cargo = this.datosSolicitud.cargoSolicitante;

        this.dialogUsuario = true;
      } else {
        //Eliminar el usuario de la lista si existe
        this.eliminarUsuario(this.datosSolicitud.correoSolicitante);
      }
    },

    resetFormUsuario() {
      this.datosUsuario.nombres = "";
      this.datosUsuario.apellidos = "";
      this.datosUsuario.correo = "";
      this.datosUsuario.telefonos = "";
      this.datosUsuario.area = "";
      this.datosUsuario.cargo = "";

      this.datosUsuario.observaciones = "";
      /* this.entidadSeleccionada = null;
            this.unidadEjecutoraSeleccionada = null;*/
      this.proyectosSeleccionados = [];
    },

    resetFormSolicitud() {
      this.solicitanteUsuario = false;
      this.nombresSolicitante = "";
      this.apellidosSolicitante = "";
      this.unidadEjecutoraId = "";
      this.entidadId = 0;
      this.entidadSeleccionada = null;
      this.unidadEjecutoraSeleccionada = null;
      this.datosSolicitud = {
        entidadId: 0,
        unidadEjecutoraId: 0,
        correoSolicitante: "",
        nombreSolicitante: "",
        cargoSolicitante: "",
        nombreAprobador: "",
        cargoAprobador: "",
        nombreMaximaAutoridad: ""
      };
    },

    //Agregar usuario al array
    agregarUsuario() {
      //Validar si se duplica el correo y proyecto

      var result = this.usuariosSolicitud.find(
        item => item.correo === this.datosUsuario.correo
      );
      //console.log(result)

      if (result) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Mensaje",
          `El usuario ${this.datosUsuario.correo} ya se encuentra agregado a la lista!`
        );
        return;
      }

      //Validar que vengan todos los datos obligatorios
      /* if(!this.entidadSeleccionada || !this.entidadSeleccionada.id){
                this.$refs.snackalert.SnackbarShow("warning", "Mensaje", `Se debe de seleccionar una institución!`);
                return;
            }
            if(!this.unidadEjecutoraSeleccionada || !this.unidadEjecutoraSeleccionada.id){
                this.$refs.snackalert.SnackbarShow("warning", "Mensaje", `La Dependencia es obligatoria!`);
                return;
            }
            if(!this.proyectoSeleccionado || !this.proyectoSeleccionado.id){
                this.$refs.snackalert.SnackbarShow("warning", "Mensaje", `Se debe de seleccionar un proyecto para poder agregar al usuario!`);
                return;
            }
            if(this.datosUsuario.nombres.length <= 0 || this.datosUsuario.apellidos.length <= 0){
                this.$refs.snackalert.SnackbarShow("warning", "Mensaje", `Los nombres y apellidos del usuario son obligatorios!`);
                return;
            }
            
            if(this.datosUsuario.correo.length || !this.entidadSeleccionada.id){
                this.$refs.snackalert.SnackbarShow("warning", "Mensaje", `Se debe de seleccionar una institución!`);
                return;
            }
            if(!this.entidadSeleccionada || !this.entidadSeleccionada.id){
                this.$refs.snackalert.SnackbarShow("warning", "Mensaje", `Se debe de seleccionar una institución!`);
                return;
            }
            if(!this.entidadSeleccionada || !this.entidadSeleccionada.id){
                this.$refs.snackalert.SnackbarShow("warning", "Mensaje", `Se debe de seleccionar una institución!`);
                return;
            }*/

      // Agregar los proyectos seleccionados al array (si es tipo proyecto CNS 1)
      let proyectosUsuario = [];
      if (this.tipoProyectoSeleccionado.id === 1) {
        proyectosUsuario = this.proyectosSeleccionados.map(function(row) {
          return {
            proyectoId: row.id,
            tipoProyecto: row.tipoProyecto,
            nombreProyecto: row.nombreProyecto
          };
        });
      }

      let nuevoUsuario = {
        nombres: this.datosUsuario.nombres,
        apellidos: this.datosUsuario.apellidos,
        correo: this.datosUsuario.correo,
        telefono: this.datosUsuario.telefonos,
        area: this.datosUsuario.area,
        cargo: this.datosUsuario.cargo,
        //tipoProyecto: this.proyectoSeleccionado.tipoProyecto,
        //proyectoId: this.proyectoSeleccionado.id,
        proyectos: proyectosUsuario,
        observaciones: this.datosUsuario.observaciones
        /*entidadId: this.entidadSeleccionada.id,
                 entidad: this.entidadSeleccionada.nombreInstitucion,
                unidadEjecutoraId: this.unidadEjecutoraSeleccionada.id,
                unidadEjecutora: this.unidadEjecutoraSeleccionada.unidadEjecutora,
                proyectoId: this.proyectoSeleccionado.id,
                proyecto: this.proyectoSeleccionado.codigo + ` ` + this.proyectoSeleccionado.nombreProyecto */
      };

      //console.log(nuevoUsuario);
      this.usuariosSolicitud.push(nuevoUsuario);

      this.dialogUsuario = false;
      this.resetFormUsuario();
    },

    //Eliminar usaurio del array
    eliminarUsuario(correo) {
      this.usuariosSolicitud.splice(
        this.usuariosSolicitud.findIndex(item => item.correo === correo),
        1
      );
    },

    detalleSolicitud() {
      this.dialogResumen = true;
    },

    //Registrar la solicitud en el sistema
    async registrarSolicitud() {
      this.mostrarMensajeDescarga = false;
      this.codigoSolicitud = "";

      this.dialogLoaderText = "Procesando solicitud, por favor espere...";
      this.dialogLoaderVisible = true;

      this.datosProceso.fecha = this.fechaActual;
      this.datosProceso.nombreSolicitante =
        this.nombresSolicitante + " " + this.apellidosSolicitante;
      this.datosProceso.correoSolicitante = this.datosSolicitud.correoSolicitante;
      this.datosProceso.cargoSolicitante = this.datosSolicitud.cargoSolicitante;
      this.datosProceso.nombreAprobador = this.datosSolicitud.nombreAprobador;
      this.datosProceso.cargoAprobador = this.datosSolicitud.cargoAprobador;
      this.datosProceso.entidadId = this.entidadSeleccionada.id;
      this.unidadEjecutoraSeleccionada
        ? (this.datosProceso.unidadEjecutoraId = this.unidadEjecutoraSeleccionada.id)
        : delete this.datosProceso.unidadEjecutoraId;
      this.datosProceso.tipoProyecto = this.tipoProyectoSeleccionado.codigo;
      this.datosProceso.nombreMaximaAutoridad = this.datosSolicitud.nombreMaximaAutoridad;
      this.datosProceso.usuarios = this.usuariosSolicitud;

      // console.log(this.datosProceso)

      await this.$store
        .dispatch(REGISTRAR_SOLICITUD_USUARIOS, { datos: this.datosProceso })
        .then(res => {
          //console.log(res)
          this.btnLoading = false;
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            // this.nombreUsuario = this.datosUsuario.nombres;
            this.dialogResumen = false;
            this.dialogLoaderVisible = false;
            this.usuariosSolicitud = [];
            this.resetFormSolicitud();
            this.datosProceso = {};
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );

            this.codigoSolicitud = res.data[0].codigoSolicitud;
            //console.log(res.extraError);
            if (res.extraError === 0) {
              this.mostrarMensajeDescarga = true;
              this.mostrarMensajeNoGenerado = false;
              this.directDownloadLink = res.data[0].pathDocumento; 
              this.descargarArchivo(res.data[0].pathDocumento);
            } else {
              this.mostrarMensajeDescarga = false;
              this.mostrarMensajeNoGenerado = true;
            }

            //this.btnRegistroLoading = false;
            //this.resetForm();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.dialogLoaderVisible = false;
        })
        .catch(error => {
          //console.log(error);
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro, por favor, comúniquese con un administrador del sistema. REF:: ${error}`
          );
          this.dialogLoaderVisible = false;
        });
    },

    //!Descargar el desde server
    descargarArchivo(path) {
      let result = DownloadFile.download(path);
      if (!result) {
        alert(
          "Por favor, habilite las ventanas emergentes para este sitio, de lo contrario, no podrá descargar el archivo solicitado."
        );
      }
    },

    descargaDirecta(url, label) {
      axios
        .get(url, {
          responseType: "blob",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": " GET, PUT, POST, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
            "Access-Control-Allow-Credentials": "false"
          }
        })
        .then(response => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click()
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    }
  },

  created() {
    let date = new Date().toJSON();
    this.fechaActual = moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
    this.obtenerEntidades();
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "/" },
      { title: "Solicitudes" },
      { title: "Usuarios Proyecto" }
    ]);
  },

  computed: {
    likesAllRoles() {
      return this.proyectosSeleccionados.length === this.proyectos.length;
    },
    likesSomeRol() {
      return this.proyectosSeleccionados.length > 0 && !this.likesAllRoles;
    },
    icon() {
      if (this.likesAllRoles) return "mdi-close-box";
      if (this.likesSomeRol) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },

    headersUsuarios() {
      return [
        { text: "Nombre", value: "nombres" },
        { text: "Correo", value: "correo" },
        /* { text: "Institución, Dependencia", value: "entidad"},*/
        { text: "Proyecto", value: "proyecto" },
        { text: "Área y Cargo", value: "area" },
        { text: "Acción", value: "accion" }
      ];
    },

    headersUsuariosResumen() {
      return [
        { text: "Nombre", value: "nombres" },
        { text: "Correo", value: "correo" },
        /*  { text: "Institución, Dependencia", value: "entidad"},*/
        { text: "Proyecto", value: "proyecto" },
        { text: "Área y Cargo", value: "area" }
      ];
    }
  }
};
</script>
<style lang="scss">
.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed.v-input--dense
  .v-select__selections {
  min-height: 40px;
  margin-top: 12px;
}
</style>
